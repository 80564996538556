import { Routes } from '@/lib/models/enums/Routes.enum'

export function getRedirectRouteByRole(permissions: string[]): string {
  if (permissions.includes('OPERATOR')) return Routes.COMPANIES
  if (permissions.includes('CUSTOMER_SUCCESS')) return Routes.USERS
  if (permissions.includes('THIRD_PARTY_CUSTOMER_SUCCESS')) return Routes.USERS
  if (permissions.includes('MARKETING')) return Routes.NOTIFICATIONS
  if (permissions.includes('OI_THIRD_PARTY_OPERATOR'))
    return Routes.OI_REGISTER_USER
  return Routes.JOBROLES
}
